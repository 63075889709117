import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'

const useStyles = makeStyles(() => {
  return {
    richTextIcon: {
      height: 48,
      width: 48,
      paddingBottom: 4,
    },
  }
})
export interface IParrotfishIcon {
  iconImage: {
    color: string
    file: {
      fileName: string
      url: string
      description: string
      contentType: string
    }
    description: string
  }
  color?: string
  width?: string
  height?: string
  className?: string
}

const ParrotfishIcon: React.FC<IParrotfishIcon> = ({
  iconImage,
  color,
  width,
  height,
  className,
}) => {
  const url = iconImage?.file?.url
  const type = iconImage?.file?.contentType

  const classes = useStyles()

  const styles = {
    mask: `url('${url}') no-repeat center / contain`,
    WebkitMask: `url('${url}') no-repeat center / contain`,
    height: '43px',
    width: '43px',
  }

  return (
    <>
      {type === 'image/svg+xml' ? (
        <SvgIcon
          className={className || classes.richTextIcon}
          htmlColor={color}
          viewBox={`0 0 ${width || 48} ${height || 48}`}
        >
          <rect width="100%" height="100%" />
        </SvgIcon>
      ) : (
        <></>
      )}
    </>
  )
}

export default ParrotfishIcon
